// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bootstrap-icons
@import '~bootstrap-icons/font/bootstrap-icons.css';

//Site App CSS
@import "../css/app.css?v=6";
