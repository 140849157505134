// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;


//Buttons
$btn-focus-width:1;
$btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.05);
